<template>
<div>
<h1>Brime Transcoding Workflow Manager</h1>
  <b-row class="mt-3">
    <!-- tag source -->
    <b-col md="6">
      <h6>NETINT Quadra&trade; Params</h6>

      <draggable
        :list="list1"
        :group="{name:'tags', pull:'clone', put:false }"
        class="mb-1 cursor-move"
      >
        <b-badge
          v-for="(listItem, index) in list1"
          :key="index"
          class="mr-25"
        >
          {{ listItem }}
        </b-badge>
      </draggable>
    </b-col>

    <!-- add tag -->
    <b-col md="6">
      <h6>Workflow Enabled Params</h6>

      <draggable
        :list="list2"
        :group="{name: 'tags'}"
        class="mb-1 cursor-move"
      >
        <b-badge
          v-for="(listItem, index) in list2"
          :key="index"
          class="mr-25"
        >
          {{ listItem }}
        </b-badge>
      </draggable>
    </b-col>
    <!-- tag source code -->
    <b-col md="6">
      <prism
        language="javascript"
        class="rounded"
      >
        Quadra Options Array: {{ list1 }}
      </prism>
    </b-col>

    <!-- tag add code -->
    <b-col md="6">
     <b-form-group 
    v-for="(listItem, index) in list2" :key="index"
    >{{ listItem }}
        <v-select
          :key="index"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
        />
      </b-form-group>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import {
  BBadge, BRow, BCol, BFormGroup,
} from 'bootstrap-vue'
import vSelect from "vue-select";
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

export default {
  components: {
    BBadge,
    draggable,
    BRow,
    Prism,
    BCol,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      option: [{ title: '1' }, { title: '2' }, { title: '3' }],
      list1: ['level', 'profile', 'device', 'hrdEnable', 'enableAUD', 'dolbyVisionProfile', 'lookAheadDepth', 'maxCLL', 'intraRefreshDuration', 'rdoLevel', 'EnableRdoQuant'],
      list2: ['lookAheadDepth', 'rdoLevel'],
    }
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>